import React, { useState, useEffect } from "react";

const formatSalary = (salary) => {
  if (!salary) return "";
  const number = parseFloat(salary.replace(/,/g, ""));
  if (isNaN(number)) return "";
  return number.toLocaleString("en-NG", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

const parseSalary = (salary) => {
  return salary.replace(/,/g, "");
};

const EmployeeForm = ({ employeeDetails, setEmployeeDetails, departments, isDisabled }) => {
  const [formattedSalary, setFormattedSalary] = useState("");

  useEffect(() => {
    if (employeeDetails.salary) {
      setFormattedSalary(formatSalary(employeeDetails.salary));
    }
  }, [employeeDetails.salary]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "salary") {
      setFormattedSalary(value);
      setEmployeeDetails((prevDetails) => ({
        ...prevDetails,
        [name]: parseSalary(value),
      }));
    } else {
      setEmployeeDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  return (
    <form className="employee__form">
      <div className="flexform">
        <div className="leftform">
          <label>
            Name:
            <input
              type="text"
              name="name"
              value={employeeDetails.name || ""}
              onChange={handleChange}
              required
              disabled={isDisabled}
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              name="email"
              value={employeeDetails.email || ""}
              onChange={handleChange}
              required
              disabled={isDisabled}
            />
          </label>
          <label>
            Phone:
            <input
              type="text"
              name="phone"
              value={employeeDetails.phone || ""}
              onChange={handleChange}
              required
              disabled={isDisabled}
            />
          </label>
          <label>
            Address:
            <input
              type="text"
              name="address"
              value={employeeDetails.address || ""}
              onChange={handleChange}
              required
              disabled={isDisabled}
            />
          </label>
          <label>
            Emergency Contact (Name):
            <input
              type="text"
              name="emergencyContactName"
              value={employeeDetails.emergencyContactName || ""}
              onChange={handleChange}
              required
              disabled={isDisabled}
            />
          </label>
          <label>
            Emergency Contact (Number):
            <input
              type="number"
              name="emergencyContactNumber"
              value={employeeDetails.emergencyContactNumber || ""}
              onChange={handleChange}
              required
              disabled={isDisabled}
            />
          </label>
          <label>
            Bank Account Name:
            <input
              type="text"
              name="accountName"
              value={employeeDetails.accountName || ""}
              onChange={handleChange}
              required
              disabled={isDisabled}
            />
          </label>
          
        </div>
        <div className="rightform">
          <label>
            Position:
            <input
              type="text"
              name="position"
              value={employeeDetails.position || ""}
              onChange={handleChange}
              required
              disabled={isDisabled}
            />
          </label>
          <label>
            Department:
            <div className="select">
              <select
                name="department"
                value={employeeDetails.department || ""}
                onChange={handleChange}
                required
                disabled={isDisabled}
                style={{
                  height: "40px",
                  marginTop: "10px",
                }}
              >
                <option value="">Select Department</option>
                {departments.map((dept, index) => (
                  <option key={index} value={dept}>
                    {dept}
                  </option>
                ))}
              </select>
            </div>
          </label>
          <label>
            Salary (NGN):
            <input
              type="text"
              name="salary"
              value={formattedSalary}
              onChange={handleChange}
              required
              disabled={isDisabled}
            />
          </label>
          <label>
            Hire Date:
            <input
              type="date"
              name="hireDate"
              value={employeeDetails.hireDate || ""}
              onChange={handleChange}
              required
              disabled={isDisabled}
            />
          </label>
          <label>
            Workplace Type:
            <div className="select">
              <select
                name="workplace"
                value={employeeDetails.workplace || ""}
                onChange={handleChange}
                required
                disabled={isDisabled}
                style={{
                  height: "40px",
                  marginTop: "10px",
                }}
              >
                <option value="">Select Workplace Type</option>
                <option value="on-site">
                  On-Site - Employees come to work in-person
                </option>
                <option value="hybrid">
                  Hybrid - Employees work on-site and off-site
                </option>
                <option value="remote">
                  Remote - Employees work off-site
                </option>
              </select>
            </div>
          </label>
          <label>
            Bank Name:
            <input
              type="text"
              name="bank"
              value={employeeDetails.bank || ""}
              onChange={handleChange}
              required
              disabled={isDisabled}
            />
          </label>
          <label>
            Bank Account Number:
            <input
              type="number"
              name="account"
              value={employeeDetails.account || ""}
              onChange={handleChange}
              required
              disabled={isDisabled}
            />
          </label>
        </div>
      </div>
    </form>
  );
};

export default EmployeeForm;
