import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { ref, deleteObject } from "firebase/storage";
import { storage, db } from "../../firebase";
import { getDoc, updateDoc, doc } from "firebase/firestore";
import "./FilesUploadForm.css";

function FilesUploadForm({
  files = [],
  setFiles,
  uploadedFiles = [],
  setUploadedFiles,
  selectedCompany,
  employeeDetails,
  setIsEditMode,
}) {

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    setIsEditMode(true);
  };

  const handleDownload = (file) => {
    const url = file.url;
    const a = document.createElement("a");
    a.href = url;
    a.download = file.name;
    a.click();
  };

  const handleDeleteNewFile = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    setIsEditMode(true);
  };

  const handleDeleteUploadedFile = async (file, index) => {
    try {
      if (file.url) {
        const filePath = `companies/${selectedCompany.id}/${employeeDetails.name}/${file.name}`;
        console.log("Deleting file at path:", filePath);
        const fileRef = ref(storage, filePath);

        if (!fileRef) {
          throw new Error(`File reference is undefined for path: ${filePath}`);
        }

        await deleteObject(fileRef);

        const employeeDocRef = doc(
          db,
          `companies/${selectedCompany.id}/employees`,
          employeeDetails.id
        );

        const employeeDoc = await getDoc(employeeDocRef);
        if (employeeDoc.exists()) {
          const currentFiles = employeeDoc.data().files || [];

          const updatedFiles = currentFiles.filter((_, i) => i !== index);

          await updateDoc(employeeDocRef, { files: updatedFiles });

          setUploadedFiles(updatedFiles);
          console.log(
            `File ${file.name} and path ${index} deleted successfully from Firebase and Firestore.`
          );
        }
      }
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <div className="fupload">
      <div className="leftupload">
        <label
          htmlFor="file-upload"
          style={{ cursor: "pointer", padding: "10px" }}
        >
          <Button
            component="label"
            variant="contained"
            tabIndex={-1}
            sx={{
              backgroundColor: "forestgreen",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <CloudUploadIcon sx={{ marginRight: "10px" }} /> Upload files
            <VisuallyHiddenInput
              id="file-upload"
              type="file"
              multiple
              onChange={handleFileChange}
            />
          </Button>
        </label>
      </div>

      <div className="rightupload">
        {uploadedFiles.map((file, index) => (
          <div key={index} className="file-container">
            {file.url && file.name && (
              <>
                {file.name.endsWith(".pdf") ? (
                  <iframe
                    src={file.url}
                    title={file.name}
                    className="file-preview"
                    frameBorder="0"
                  />
                ) : file.type?.startsWith("image/") ? (
                  <img
                    src={file.url}
                    alt={file.name}
                    className="file-preview"
                  />
                ) : (
                  <div className="file-preview">{file.name}</div>
                )}
                <div className="file-name">{file.name}</div>
                <button
                  className="download-button"
                  onClick={() => handleDownload(file)}
                >
                  <CloudDownloadIcon />
                </button>
                <button
                  className="del-button"
                  onClick={() => handleDeleteUploadedFile(file, index)}
                >
                  <DeleteForeverIcon />
                </button>
              </>
            )}
          </div>
        ))}

        {files.map((file, index) => (
          <div key={index} className="file-container">
            {file.type.startsWith("image/") ? (
              <img
                src={URL.createObjectURL(file)}
                alt={file.name}
                className="file-preview"
              />
            ) : file.type === "application/pdf" ? (
              <iframe
                src={URL.createObjectURL(file)}
                title={file.name}
                className="file-preview"
              />
            ) : (
              <div>{file.name}</div>
            )}
            <div className="file-name">{file.name}</div>
            <button
              className="del-button"
              onClick={() => handleDeleteNewFile(index)}
            >
              <DeleteForeverIcon />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FilesUploadForm;
