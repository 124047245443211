import React, { useState, useEffect } from "react";
import "./Company.css";
import Navbar from "../../Component/Navbar/Navbar";
import CompanyList from "./CompanyList";
import CompanyDetails from "./CompanyDetails";
import AddEmployee from "./AddEmployee";
import Modal from "./Modal";
import EmployeeModal from "./EmployeeModal";
import EditCompanyModal from "./EditCompanyModal";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";

export default function Company() {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    if (loading) {
      setSelectedCompany(null);
    }
  }, [loading]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get('modal') === 'open') {
      setIsModalOpen(true);
    }
  }, [location]);

  const fetchCompanies = async () => {
    setLoading(true);
    try {
      const companiesCollection = collection(db, "companies");
      const companiesSnapshot = await getDocs(companiesCollection);
      const companyList = companiesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCompanies(companyList);
    } catch (error) {
      console.error("Error fetching companies: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCompanySelect = (company) => {
    if (selectedCompany?.id === company.id) {
      setSelectedCompany(null);
    } else {
      setSelectedCompany(company);
    }
  };

  const handleEditCompanyClick = (company) => {
    setSelectedCompany(company);
    setIsEditModalOpen(true);
  };

  const openModal = () => {
    setIsModalOpen(true);
    navigate('/company?modal=open');
  };

  const closeModal = () => {
    setIsModalOpen(false);
    fetchCompanies();
    navigate('/company');
  };

  const openEmployeeModal = () => {
    if (selectedCompany) {
      setSelectedCompany(null);
    }
    setIsEmployeeModalOpen(true);
  };
  
  const closeEmployeeModal = () => {
    setIsEmployeeModalOpen(false);
    fetchCompanies();
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    fetchCompanies();
  };

  return (
    <div>
      <Navbar />
      <div className="company-container">
        <div className="company-content">
          <div className="company-grid">
            <div className="column company-card company-list">
              {loading ? (
                <p>Loading companies...</p>
              ) : (
                <CompanyList
                  companies={companies}
                  selectedCompany={selectedCompany}
                  onCompanySelect={handleCompanySelect}
                  onAddCompanyClick={openModal}
                  onEditCompanyClick={handleEditCompanyClick}
                />
              )}
            </div>

            {selectedCompany && (
              <div className="column company-card">
                <CompanyDetails company={selectedCompany} />
                <AddEmployee
                  company={selectedCompany}
                  onAddEmployeeClick={openEmployeeModal}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={closeModal} />
      <EmployeeModal
        isOpen={isEmployeeModalOpen}
        onClose={closeEmployeeModal}
        selectedCompany={selectedCompany}
      />
      <EditCompanyModal
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        selectedCompany={selectedCompany}
      />
    </div>
  );
}
