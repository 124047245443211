import React, { useState } from "react";
import "./Login.css";
import LoginLogo from "../../Component/Assets/images/hraplogo.png";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNotifications } from "../../NotificationContext";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { addNotification } = useNotifications();

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        navigate("/");
        addNotification("→ Welcome back! Sign-in Successful");
      })
      .catch((error) => {
        setError("Email or Password incorrect");
        setLoading(false);
      });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError("");
  };

  return (
    <div className="login">
      <a href="/">
        <img className="login__logo" src={LoginLogo} alt="hrap" />
      </a>

      <div className="login_container">
        <form className="form_login">
          <input
            type="text"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
          />

          {error && <div className="error">{error}</div>}

          <p>
            <Link className="forgot-password" to="/reset-password">
              Forgot Password?
            </Link>
          </p>
          <button
            type="submit"
            onClick={handleLogin}
            className="Login_signInButton"
            disabled={loading}
          >
            {loading ? "Signing in..." : "Sign In"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
