import { initializeApp } from "firebase/app";
import { getAuth, updateProfile } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDVE89iC_YBscxPl-4_VH4nI4mORkB76zw",
  authDomain: "hrap-suite.firebaseapp.com",
  projectId: "hrap-suite",
  storageBucket: "hrap-suite.appspot.com",
  messagingSenderId: "990067866615",
  appId: "1:990067866615:web:91ae4a10fff31e17e54cf2",
  measurementId: "G-FB1GDC30Z9",
};

const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);

export { db, auth, storage, updateProfile };
