import React, { useState, useEffect } from "react";
import "./Modal.css";
import "./EmployeeDetails.css";
import EmployeeForm from "./EmployeeForm";
import PerformanceForm from "./PerformanceForm";
import FilesUploadForm from "./FilesUploadForm";
import { storage, db } from "../../firebase";
import { doc, updateDoc, getDoc, deleteDoc } from "firebase/firestore";
import { ref, deleteObject, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNotifications } from "../../NotificationContext";

export default function EmployeeDetails({
  isOpen,
  onClose,
  selectedCompany,
  employeeDetails,
  isDisabled,
  onEmployeeSaved,
}) {
  const [activeTab, setActiveTab] = useState("employeeDetails");
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [currentDetails, setCurrentDetails] = useState(employeeDetails);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { addNotification } = useNotifications();
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    setCurrentDetails(employeeDetails);
    fetchFiles();
  }, [employeeDetails]);

  const fetchFiles = async () => {
    try {
      const employeeDocRef = doc(
        db,
        `companies/${selectedCompany.id}/employees`,
        currentDetails.id
      );
      const employeeDoc = await getDoc(employeeDocRef);

      if (employeeDoc.exists()) {
        const files = employeeDoc.data().files || [];
        setUploadedFiles(files);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching files:", error);
      toast.error("Error fetching files");
    }
  };

  const handleTabChange = (tab) => setActiveTab(tab);

  const handleEdit = () => setIsEditMode(true);

  const handleSave = async () => {
    setIsSaving(true);
  
    try {
      const employeeDocRef = doc(
        db,
        `companies/${selectedCompany.id}/employees`,
        currentDetails.id
      );
      await updateDoc(employeeDocRef, { ...currentDetails });
  
      if (selectedFiles.length > 0) {
        const newFiles = await Promise.all(
          selectedFiles.map(async (file) => {
            const fileRef = ref(
              storage,
              `companies/${selectedCompany.id}/${employeeDetails.name}/${file.name}`
            );
            const uploadTask = uploadBytesResumable(fileRef, file);
            return new Promise((resolve, reject) => {
              uploadTask.on(
                "state_changed",
                null,
                (error) => reject(error),
                async () => {
                  const url = await getDownloadURL(fileRef);
                  resolve({ name: file.name, url });
                }
              );
            });
          })
        );
  
        const filesRef = doc(db, `companies/${selectedCompany.id}/employees`, currentDetails.id);
        const existingFiles = currentDetails.files || [];
        await updateDoc(filesRef, {
          files: [...existingFiles, ...newFiles]
        });
  
        setUploadedFiles((prev) => [...prev, ...newFiles]);
      }
  
      addNotification("→ Employee details updated successfully");
      toast.success("Employee details updated successfully");
      setIsEditMode(false);
      onEmployeeSaved();
      onClose();
    } catch (error) {
      console.error("Error updating employee:", error);
      toast.error("Error updating employee details");
    } finally {
      setIsSaving(false);
    }
  };

  const handleDiscard = () => {
    setCurrentDetails(employeeDetails);
    setIsEditMode(false);
    onClose();
  };

  const handleDelete = async () => {
    const confirmation = window.confirm("Are you sure you want to remove this employee?");
    if (!confirmation) return;

    setIsSaving(true);
    
    try {
      const employeeDocRef = doc(
        db,
        `companies/${selectedCompany.id}/employees`,
        currentDetails.id
      );
      await deleteDoc(employeeDocRef);

      for (const file of uploadedFiles) {
        const fileRef = ref(
          storage,
          `companies/${selectedCompany.id}/${employeeDetails.name}/${file.name}`
        );
        await deleteObject(fileRef);
      }

      addNotification("→ An employee has been removed from your company.");
      toast.success("Employee removed successfully");
      onEmployeeSaved();
      onClose();
    } catch (error) {
      console.error("Error removing employee:", error);
      toast.error("Error removing employee");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    isOpen && (
      <div className="modal-overlay">
        <div className="modal-content">
          <div className="modal-header flxdetails">
            <h5 className="llp_details">Employee Details</h5>
            <div className="rrp_details">
              {isDisabled && !isEditMode && (
                <button 
                  className="ed-button" 
                  onClick={handleEdit}
                  disabled={isSaving}
                >
                  Edit
                </button>
              )}
              <button
                className="dell-button"
                onClick={handleDelete}
                disabled={isSaving}
              >
                Remove Employee
              </button>
            </div>
          </div>
          <div className="modal-tabs">
            <button
              className={`tab-button ${
                activeTab === "employeeDetails" ? "active" : ""
              }`}
              onClick={() => handleTabChange("employeeDetails")}
              disabled={isSaving}
            >
              Information
            </button>
            <button
              className={`tab-button ${
                activeTab === "performance" ? "active" : ""
              }`}
              onClick={() => handleTabChange("performance")}
              disabled={isSaving}
            >
              Performance & Comments
            </button>
            <button
              className={`tab-button ${activeTab === "files" ? "active" : ""}`}
              onClick={() => handleTabChange("files")}
              disabled={isSaving}
            >
              Files
            </button>
          </div>
          <div className="modal-body">
            {activeTab === "employeeDetails" && (
              <EmployeeForm
                employeeDetails={currentDetails}
                setEmployeeDetails={setCurrentDetails}
                departments={selectedCompany?.departments || []}
                isDisabled={!isEditMode}
              />
            )}
            {activeTab === "performance" && (
              <PerformanceForm
                performancePosts={currentDetails.performance || []}
                setPerformancePosts={(posts) =>
                  setCurrentDetails((prev) => ({ ...prev, performance: posts }))
                }
                setIsEditMode={setIsEditMode}
              />
            )}
            {activeTab === "files" && (
              <FilesUploadForm
                files={selectedFiles}
                setFiles={setSelectedFiles}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                selectedCompany={selectedCompany}
                employeeDetails={employeeDetails}
                setIsEditMode={setIsEditMode}
              />
            )}
          </div>
          <div className="modal-footer">
            <button 
              className="discard-btn" 
              onClick={handleDiscard}
              disabled={isSaving}
            >
              {isEditMode ? "Discard" : "Close"}
            </button>
            {isEditMode && (
              <button 
                className="save-btn" 
                onClick={handleSave}
                disabled={isSaving}
              >
                {isSaving ? "Saving..." : "Save"}
              </button>
            )}
          </div>
        </div>
        <ToastContainer />
      </div>
    )
  );
}
