import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./ResetPassword.css";
import ResetLogo from "../../Component/Assets/images/hraplogo.png";
import { auth } from "../../firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [attempts, setAttempts] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent! Please check your inbox.");
      setError("");
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch {
      let errorMsg;
      setAttempts((prevAttempts) => {
        const newAttempts = prevAttempts + 1;
        switch (newAttempts) {
          case 1:
            errorMsg = "🤔 Oops, are you sure that’s right? Please try again.";
            break;
          case 2:
            errorMsg =
              "Can’t find an account with that email. Please try again.";
            break;
          case 3:
            errorMsg = "Failed to send password reset email. Please try again.";
            setTimeout(() => {
              navigate("/");
            }, 3000);
            break;
          default:
            errorMsg = "Failed to send password reset email. Please try again.";
            break;
        }
        setError(errorMsg);
        return newAttempts;
      });
      setMessage("");
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  return (
    <div className="reset-password-container">
      <header className="reset-password-header">
        <img src={ResetLogo} alt="hrap Logo" className="login__logo" />
      </header>
      <main className="reset-password-main">
        <Link to="/login" className="reset_back-link float-left">
          <ArrowBackIosIcon fontSize="" /> Back to Log in
        </Link>
        <h2>Reset Password</h2>
        <div className="reset_container">
          <form onSubmit={handleSubmit} className="reset-password-form">
            <input
              type="email"
              placeholder="Email Address"
              value={email}
              onChange={handleEmailChange}
              required
            />
            <button type="submit" disabled={loading}>
              {loading ? "Resetting..." : "Reset Password"}
            </button>
          </form>
        </div>
        {message && <div className="message">{message}</div>}
        {error && <div className="error">{error}</div>}
      </main>
    </div>
  );
};

export default ResetPassword;
