import React, { createContext, useContext, useState } from "react";
import {
  collection,
  addDoc,
  updateDoc,
  query,
  where,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { db, auth } from "./firebase";

const NotificationContext = createContext();

export const useNotifications = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = async (message) => {
    const timestamp = new Date().toISOString();
    const docRef = await addDoc(collection(db, "notifications"), {
      owner: auth.currentUser?.uid,
      message,
      timestamp,
    });
    await updateDoc(docRef, { id: docRef.id });

    setNotifications((prev) => [
      ...prev,
      { message, id: docRef.id, timestamp },
    ]);
  };

  const fetchNotifications = async () => {
    const userId = auth.currentUser?.uid;
    if (userId) {
      const notificationsQuery = query(
        collection(db, "notifications"),
        where("owner", "==", userId),
        orderBy("timestamp", "desc")
      );
      const notificationSnapshot = await getDocs(notificationsQuery);
      const notificationsList = notificationSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNotifications(notificationsList);
    }
  };

  return (
    <NotificationContext.Provider
      value={{ notifications, addNotification, fetchNotifications }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
