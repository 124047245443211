import React, { useState, useEffect, useRef } from "react";
import Navbar from "../Navbar/Navbar";
import emailjs from "@emailjs/browser";
import "./Settings.css";
import { auth, storage } from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  signOut,
  deleteUser,
  updateProfile as firebaseUpdateProfile,
} from "firebase/auth";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "../../NotificationContext";

export default function Settings() {
  const [user, setUser] = useState(auth.currentUser);
  const [newPhoto, setNewPhoto] = useState(null);
  const [previewURL, setPreviewURL] = useState(user?.photoURL || null);
  const [uploading, setUploading] = useState(false);
  const [firstName, setFirstName] = useState(
    user?.displayName?.split(" ")[0] || ""
  );
  const [lastName, setLastName] = useState(
    user?.displayName?.split(" ")[1] || ""
  );
  const [savingName, setSavingName] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const navigate = useNavigate();
  const form = useRef();
  const { addNotification } = useNotifications();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        setPreviewURL(currentUser.photoURL);
        const [first, last] = currentUser.displayName?.split(" ") || ["", ""];
        setFirstName(first);
        setLastName(last);
      } else {
        setPreviewURL(null);
        setFirstName("");
        setLastName("");
      }
    });

    return () => unsubscribe();
  }, [refreshKey]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setNewPhoto(file);

    if (file) {
      const objectURL = URL.createObjectURL(file);
      setPreviewURL(objectURL);
    } else {
      setPreviewURL(null);
    }
  };

  const handleUpload = async () => {
    if (newPhoto && user) {
      setUploading(true);
      try {
        const photoRef = ref(storage, `profilePictures/${user.uid}`);
        await uploadBytes(photoRef, newPhoto);
        const url = await getDownloadURL(photoRef);
        await firebaseUpdateProfile(user, { photoURL: url });
        setUser({ ...user, photoURL: url });
        addNotification("→ Your profile picture has been changed");
        toast.success("Profile picture updated successfully!");
        setRefreshKey((prevKey) => prevKey + 1);
      } catch (error) {
        toast.error("Error updating profile picture");
        console.error(error);
      } finally {
        setUploading(false);
      }
    }
  };

  const handleNameSave = async () => {
    if (user) {
      setSavingName(true);
      try {
        const displayName = `${firstName} ${lastName}`;
        await firebaseUpdateProfile(user, { displayName });
        setUser({ ...user, displayName });
        addNotification("→ Your name has been updated successfully!");
        toast.success("Name updated successfully!");
        setRefreshKey((prevKey) => prevKey + 1);
      } catch (error) {
        toast.error("Failed to update name");
        console.error(error);
      } finally {
        setSavingName(false);
      }
    }
  };

  const handleLogOut = async () => {
    try {
      await signOut(auth);
      toast.success("Logged out successfully!");
    } catch (error) {
      toast.error("Failed to log out");
      console.error(error);
    }
  };

  const handleDeleteAccount = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete your account? This action cannot be undone."
      )
    ) {
      try {
        if (user) {
          await deleteUser(user);
          toast.success("Account deleted successfully!");
          navigate("/login");
        }
      } catch (error) {
        toast.error("Failed to delete account");
        console.error(error);
      }
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setSendingEmail(true);
    const submitButton = e.target.querySelector(".add-button");
    submitButton.disabled = true;

    emailjs
      .sendForm("service_kjjokg6", "template_ar974se", form.current, {
        publicKey: "DHhTcwyiLDvMh3zm2",
      })
      .then(
        () => {
          toast.success("Support request sent successfully!");
          e.target.reset();
        },
        (error) => {
          toast.error("Failed to send support request.");
          console.error(error);
        }
      )
      .finally(() => {
        setSendingEmail(false);
        submitButton.disabled = false;
      });
  };

  return (
    <div>
      <Navbar />
      <div className="home-container">
        <div className="dashboard-content">
          <div className="dashboard-grid">
            <div className="dashboard-card">
              <h5>Profile Information</h5>
              <div className="profile-pic-container">
                <div className="flexed">
                  <input type="file" onChange={handleFileChange} />
                </div>

                <button
                  className="add-button butttton mt-1 mb-4 fs-6"
                  onClick={handleUpload}
                  disabled={!newPhoto || uploading}
                >
                  {uploading ? "Uploading..." : "Change Picture"}
                </button>
                <div className="img-con float-end">
                  <Avatar
                    src={previewURL || user?.photoURL}
                    variant="square"
                    sx={{ width: 100, height: 100 }}
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="name-update-section form-group">
                  <input
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <button
                    onClick={handleNameSave}
                    className="add-button"
                    disabled={savingName}
                  >
                    {savingName ? "Saving..." : "Save"}
                  </button>
                </div>
              </div>
            </div>

            <div className="dashboard-card">
              <h5>Contact Support</h5>
              <form ref={form} className="php-email-form" onSubmit={sendEmail}>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="from_name"
                      className="form-control"
                      id="from_name"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mt-md-0">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    placeholder="Subject"
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="3"
                    placeholder="Message"
                    required
                  ></textarea>
                </div>
                <br />
                <div className="text-center">
                  <button
                    type="submit"
                    className="add-button"
                    disabled={sendingEmail}
                  >
                    {sendingEmail ? "Sending..." : "Send Message"}
                  </button>
                </div>
              </form>
            </div>

            <div className="dashboard-card">
              <h5>Account Management</h5>
              <button
                className="discard-btn butttton mt-3 pt-2 pb-2 fs-6"
                onClick={handleLogOut}
              >
                <PowerSettingsNewIcon /> Log Out
              </button>
              <button
                onClick={handleDeleteAccount}
                className="dell-button butttton mt-3 pt-2 pb-2 fs-6 float-end"
              >
                <DeleteIcon /> Delete Account
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2500} />
    </div>
  );
}
