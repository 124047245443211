import React, { useRef, useState } from "react";
import "./Modal.css";
import EmployeeForm from "./EmployeeForm";
import PerformanceForm from "./PerformanceForm";
import FilesUploadForm from "./FilesUploadForm";
import { storage, db } from "../../firebase"; // Import Firestore and Storage instances
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"; // Firebase Storage functions
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNotifications } from "../../NotificationContext";

export default function EmployeeModal({
  isOpen,
  onClose,
  selectedCompany,
  isDisabled,
  onEmployeeSaved,
}) {
  const [activeTab, setActiveTab] = useState("employeeDetails");
  const [isSaving, setIsSaving] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState({});
  const [performancePosts, setPerformancePosts] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const formRefs = useRef({});
  const [isEditMode, setIsEditMode] = useState(false);
  const { addNotification } = useNotifications();

  const capitalizeWords = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleSave = async () => {
    if (
      !employeeDetails.name ||
      !employeeDetails.email ||
      !employeeDetails.department
    ) {
      toast.error(
        "Please fill out the required fields in the Employee Details form."
      );
      return;
    }

    setIsSaving(true);

    try {
      const capitalizedEmployeeDetails = {
        name: capitalizeWords(employeeDetails.name),
        email: employeeDetails.email.toLowerCase(),
        department: capitalizeWords(employeeDetails.department),
        position: capitalizeWords(employeeDetails.position),
        address: capitalizeWords(employeeDetails.address),
        bank: capitalizeWords(employeeDetails.bank),
        emergencyContactName: capitalizeWords(
          employeeDetails.emergencyContactName
        ),
        accountName: capitalizeWords(employeeDetails.accountName),
        phone: employeeDetails.phone,
        salary: employeeDetails.salary,
        workplace: employeeDetails.workplace,
        emergencyContactNumber: employeeDetails.emergencyContactNumber,
        account: employeeDetails.account,
        hireDate: employeeDetails.hireDate,
        performance: performancePosts,
        files: [],
      };

      const employeeDocRef = await addDoc(
        collection(db, `companies/${selectedCompany.id}/employees`),
        capitalizedEmployeeDetails
      );

      if (selectedFiles.length > 0) {
        const fileUploadPromises = selectedFiles.map(async (file) => {
          const fileRef = ref(
            storage,
            `companies/${selectedCompany.id}/${capitalizedEmployeeDetails.name}/${file.name}`
          );
          const uploadTask = uploadBytesResumable(fileRef, file);

          return new Promise((resolve, reject) => {
            uploadTask.on(
              "state_changed",
              null,
              (error) => {
                console.error("File upload error:", error);
                reject(error);
              },
              async () => {
                const url = await getDownloadURL(fileRef);
                resolve(url);
              }
            );
          });
        });

        const fileUrls = await Promise.all(fileUploadPromises);

        await updateDoc(employeeDocRef, {
          files: fileUrls.map((url, index) => ({
            name: selectedFiles[index].name,
            url,
          })),
        });

        console.log("Files uploaded successfully:", fileUrls);
      }

      toast.success("Employee added successfully");
      addNotification("→ A new employee has been added to your company.");
      setEmployeeDetails({});
      setSelectedFiles([]);
      setPerformancePosts([]);
      onEmployeeSaved();
      onClose();
    } catch (error) {
      console.error("Error saving employee:", error);
      toast.error("Error saving employee details");
    } finally {
      setIsSaving(false);
    }
  };

  const handleDiscard = () => {
    setEmployeeDetails({});
    setPerformancePosts([]);
    setSelectedFiles([]);
    onClose();
  };

  return (
    isOpen && (
      <div className="modal-overlay">
        <div className="modal-content">
          <div className="modal-header">
            <h5>Add Employee</h5>
          </div>
          <div className="modal-tabs">
            <button
              className={`tab-button ${
                activeTab === "employeeDetails" ? "active" : ""
              }`}
              onClick={() => handleTabChange("employeeDetails")}
            >
              Information
            </button>
            <button
              className={`tab-button ${
                activeTab === "performance" ? "active" : ""
              }`}
              onClick={() => handleTabChange("performance")}
            >
              Performance & Comments
            </button>
            <button
              className={`tab-button ${activeTab === "files" ? "active" : ""}`}
              onClick={() => handleTabChange("files")}
            >
              Files
            </button>
          </div>
          <div className="modal-body">
            {activeTab === "employeeDetails" && (
              <EmployeeForm
                employeeDetails={employeeDetails}
                setEmployeeDetails={setEmployeeDetails}
                departments={selectedCompany?.departments || []}
                isDisabled={false}
              />
            )}
            {activeTab === "performance" && (
              <PerformanceForm
                performancePosts={performancePosts}
                setPerformancePosts={setPerformancePosts}
                setIsEditMode={setIsEditMode}
              />
            )}
            {activeTab === "files" && (
              <FilesUploadForm
                files={selectedFiles}
                setFiles={setSelectedFiles}
                uploadedFiles={[]}
                setUploadedFiles={setUploadedFiles}
                selectedCompany={selectedCompany}
                employeeDetails={employeeDetails}
                setIsEditMode={setIsEditMode}
              />
            )}
          </div>
          <div className="modal-footer">
            <button
              className="discard-btn"
              onClick={handleDiscard}
              disabled={isSaving}
            >
              Discard
            </button>
            <button
              className="save-btn"
              onClick={handleSave}
              disabled={isSaving}
            >
              {isSaving ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    )
  );
}
