import React, { useState, useRef } from "react";
import "./Modal.css";
import CompanyDetailsForm from "./CompanyDetailsForm";
import CompanyImage from "./CompanyImage";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { auth, storage } from "../../firebase";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { collection, addDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useNotifications } from "../../NotificationContext";

const Modal = ({ isOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState("companyDetails");
  const [companyDetails, setCompanyDetails] = useState({});
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const formRefs = useRef({});
  const { addNotification } = useNotifications();

  const capitalizeWords = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleSave = async () => {
    const companyDetailsForm = formRefs.current["companyDetails"]?.form;
    if (companyDetailsForm && !companyDetailsForm.reportValidity()) {
      toast.error(
        "Please fill out the required fields in the Company Details form."
      );
      return;
    }

    if (!imageFile) {
      toast.error("Please upload an image.");
      return;
    }

    setIsSaving(true);

    try {
      let logoUrl = "";
      if (imageFile && companyDetails.name) {
        const storageRef = ref(
          storage,
          `company_images/${capitalizeWords(companyDetails.name)}_logo`
        );
        await uploadBytes(storageRef, imageFile);
        logoUrl = await getDownloadURL(storageRef);
      }

      const capitalizedDetails = {
        name: capitalizeWords(companyDetails.name),
        address: capitalizeWords(companyDetails.address),
        field: capitalizeWords(companyDetails.field),
        size: capitalizeWords(companyDetails.size),
        yearFounded: companyDetails.yearFounded,
        departments: companyDetails.departments.map(capitalizeWords),
      };

      const docRef = await addDoc(collection(db, "companies"), {
        ...capitalizedDetails,
        logo: logoUrl,
        owner: auth.currentUser?.uid,
      });

      await updateDoc(doc(db, "companies", docRef.id), { id: docRef.id });

      addNotification("→ You successfully added a company");
      toast.success(`${capitalizedDetails.name} added successfully`);

      Object.keys(formRefs.current).forEach((tab) => {
        if (formRefs.current[tab]?.clearForm) {
          formRefs.current[tab].clearForm();
        }
      });

      setCompanyDetails({});
      setImageFile(null);
      setImagePreview(null);

      onClose();
    } catch (error) {
      console.error("Error uploading image or saving company: ", error);
      toast.error("Error saving company details");
    } finally {
      setIsSaving(false);
    }
  };

  const handleDiscard = () => {
    Object.keys(formRefs.current).forEach((tab) => {
      if (formRefs.current[tab]?.clearForm) {
        formRefs.current[tab].clearForm();
      }
    });

    setCompanyDetails({});
    setImageFile(null);
    setImagePreview(null);

    onClose();
  };

  const registerForm = (name, ref) => {
    formRefs.current[name] = ref;
  };

  const handleImageUpload = (file) => {
    setImageFile(file);
    setImagePreview(URL.createObjectURL(file));
  };

  return (
    isOpen && (
      <div className="modal-overlay">
        <div className="modal-content">
          <div className="modal-header">
            <h5>Add Company</h5>
          </div>
          <div className="modal-tabs">
            <button
              className={`tab-button ${
                activeTab === "companyDetails" ? "active" : ""
              }`}
              onClick={() => handleTabChange("companyDetails")}
            >
              Company Details
            </button>
            <button
              className={`tab-button ${activeTab === "image" ? "active" : ""}`}
              onClick={() => handleTabChange("image")}
            >
              Company Logo
            </button>
          </div>
          <div className="modal-body">
            {activeTab === "companyDetails" && (
              <CompanyDetailsForm
                registerForm={registerForm}
                setCompanyDetails={setCompanyDetails}
                companyDetails={companyDetails}
              />
            )}
            {activeTab === "image" && (
              <CompanyImage
                companyName={companyDetails.name}
                onImageUpload={handleImageUpload}
                imagePreview={imagePreview}
              />
            )}
          </div>
          <div className="modal-footer">
            <button
              className="discard-btn"
              onClick={handleDiscard}
              disabled={isSaving}
            >
              Discard
            </button>
            <button
              className="save-btn"
              onClick={handleSave}
              disabled={isSaving}
            >
              {isSaving ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    )
  );
};

export default Modal;
