import React from "react";
import NoImage from "../../Component/Assets/images/no-image.jpg";
import "./CompanyImage.css";

export default function CompanyImage({ companyName, onImageUpload, imagePreview }) {
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      onImageUpload(file);
    }
  };

  return (
    <div>
      <div className="wrapper">
        <div className="box">
          <div
            className="js--image-preview"
            style={{ backgroundImage: imagePreview ? `url(${imagePreview})` : `url(${NoImage})` }}
          ></div>
          <div className="upload-options">
            <label>
              <input
                type="file"
                className="image-upload"
                accept="image/*"
                onChange={handleImageUpload}
                required
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
