import React, { useState } from "react";
import { Avatar } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  ref,
  uploadBytes,
  deleteObject,
  getDownloadURL,
} from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../firebase";
import "./CompanyDetails.css";

export default function CompanyDetails({ company }) {
  const [isEditing, setIsEditing] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewImage(URL.createObjectURL(file));

      try {
        setIsUploading(true);

        if (company.logo) {
          const oldImageRef = ref(
            storage,
            `company_images/${company.name}_logo`
          );
          await deleteObject(oldImageRef);
        }

        const storageRef = ref(storage, `company_images/${company.name}_logo`);
        await uploadBytes(storageRef, file);
        const newLogoUrl = await getDownloadURL(storageRef);

        const companyDoc = doc(db, "companies", company.id);
        await updateDoc(companyDoc, { logo: newLogoUrl });

        setIsUploading(false);
        setIsEditing(false);

        window.location.reload();
      } catch (error) {
        console.error("Error updating logo:", error);
        setIsUploading(false);
      }
    }
  };

  return (
    <div>
      <h5>{company.name} Details</h5>
      <p>Company Address: {company.address}</p>
      <p>Company Field: {company.field}</p>
      <p>Company Size: {company.size}</p>
      <p>Founded Date: {company.yearFounded}</p>
      <div className="cdflex"></div>
      <p>Departments: {company.departments.join(", ")}</p>

      <div className="llright">
        <div
          className="image-container"
          onMouseEnter={() => setIsEditing(true)}
          onMouseLeave={() => setIsEditing(false)}
        >
          <Avatar
            alt={company.name}
            src={newImage || company.logo}
            sx={{ width: 110, height: 110 }}
            variant="rounded"
            className={`company-avatar ${isEditing ? "blurred" : ""}`}
          />
          {isEditing && (
            <div className="edit-overlay">
              <label>
                <EditIcon fontSize="large" className="edit-icon" />
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="hidden-input"
                />
              </label>
            </div>
          )}
        </div>
        {isUploading && <p></p>}
      </div>
    </div>
  );
}
