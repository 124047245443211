import React, { useState, useEffect, useRef } from "react";
import "./Modal.css";
import CompanyDetailsForm from "./CompanyDetailsForm";
import CompanyImage from "./CompanyImage";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import { auth, storage } from "../../firebase";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  doc,
  updateDoc,
  deleteDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useNotifications } from "../../NotificationContext";

const EditCompanyModal = ({ isOpen, onClose, selectedCompany }) => {
  const [activeTab, setActiveTab] = useState("companyDetails");
  const [companyDetails, setCompanyDetails] = useState({});
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const formRefs = useRef({});
  const { addNotification } = useNotifications();

  const capitalizeWords = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  useEffect(() => {
    if (selectedCompany) {
      setCompanyDetails(selectedCompany);
      setImagePreview(selectedCompany.logo);
    }
  }, [selectedCompany]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleSave = async () => {
    const companyDetailsForm = formRefs.current["companyDetails"]?.form;
    if (companyDetailsForm && !companyDetailsForm.reportValidity()) {
      toast.error(
        "Please fill out the required fields in the Company Details form."
      );
      return;
    }

    setIsSaving(true);

    try {
      let logoUrl = imagePreview; // Use the current logo URL if no new image is uploaded
      if (imageFile && companyDetails.name) {
        const storageRef = ref(
          storage,
          `company_images/${capitalizeWords(companyDetails.name)}_logo` // Capitalize name for new logo path
        );
        await uploadBytes(storageRef, imageFile); // Upload the new logo if any
        logoUrl = await getDownloadURL(storageRef); // Get the new logo URL
      }

      // Capitalize company details
      const capitalizedDetails = {
        name: capitalizeWords(companyDetails.name),
        address: capitalizeWords(companyDetails.address),
        field: capitalizeWords(companyDetails.field),
        size: capitalizeWords(companyDetails.size),
        yearFounded: companyDetails.yearFounded,
        departments: companyDetails.departments.map(capitalizeWords),
        logo: logoUrl, // Update logo URL
      };

      // Update company details in Firestore
      const companyRef = doc(db, "companies", selectedCompany.id);
      await updateDoc(companyRef, capitalizedDetails);

      toast.success(`${capitalizedDetails.name} updated successfully`);
      addNotification("→ Company details updated successfully.");
      onClose();
    } catch (error) {
      console.error("Error updating company: ", error);
      toast.error("Error updating company details");
    } finally {
      setIsSaving(false);
    }
  };

  const handleDiscard = () => {
    setCompanyDetails({});
    setImageFile(null);
    setImagePreview(null);
    onClose();
  };

  const registerForm = (name, ref) => {
    formRefs.current[name] = ref;
  };

  const handleImageUpload = (file) => {
    setImageFile(file);
    setImagePreview(URL.createObjectURL(file));
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to remove this company?")) {
      if (!selectedCompany) return;

      setIsSaving(true);

      try {
        if (selectedCompany.logo) {
          const logoRef = ref(
            storage,
            `company_images/${selectedCompany.name}_logo`
          );
          await deleteObject(logoRef);
        }

        const employeesCollection = collection(
          db,
          `companies/${selectedCompany.id}/employees`
        );
        const employeesSnapshot = await getDocs(employeesCollection);

        for (const employeeDoc of employeesSnapshot.docs) {
          const employeeData = employeeDoc.data();
          const employeeFiles = employeeData.files || [];

          await Promise.all(
            employeeFiles.map(async (file) => {
              const fileRef = ref(
                storage,
                `companies/${selectedCompany.id}/${employeeData.name}/${file.name}`
              );
              await deleteObject(fileRef);
            })
          );

          await deleteDoc(employeeDoc.ref);
        }

        await deleteDoc(doc(db, "companies", selectedCompany.id));

        window.alert(`${selectedCompany.name} deleted successfully`);
        addNotification("→ You deleted a company");
        onClose();
      } catch (error) {
        console.error("Error deleting company: ", error);
        window.alert("Error deleting company");
      } finally {
        setIsSaving(false);
      }
    }
  };

  return (
    isOpen && (
      <div className="modal-overlay">
        <div className="modal-content">
          <div className="modal-header flxdetails">
            <h5 className="llp_details">Company Details</h5>
            <div className="rrp_details">
              <button
                className="dell-button"
                onClick={handleDelete}
                disabled={isSaving}
              >
                Remove Company
              </button>
            </div>
          </div>
          <div className="modal-tabs">
            <button
              className={`tab-button ${
                activeTab === "companyDetails" ? "active" : ""
              }`}
              onClick={() => handleTabChange("companyDetails")}
            >
              Company Details
            </button>
            <button
              className={`tab-button ${activeTab === "image" ? "active" : ""}`}
              onClick={() => handleTabChange("image")}
            >
              Company Logo
            </button>
          </div>
          <div className="modal-body">
            {activeTab === "companyDetails" && (
              <CompanyDetailsForm
                registerForm={registerForm}
                setCompanyDetails={setCompanyDetails}
                companyDetails={companyDetails}
              />
            )}
            {activeTab === "image" && (
              <CompanyImage
                companyName={companyDetails.name}
                onImageUpload={handleImageUpload}
                imagePreview={imagePreview}
              />
            )}
          </div>
          <div className="modal-footer">
            <button
              className="discard-btn"
              onClick={handleDiscard}
              disabled={isSaving}
            >
              Discard
            </button>
            <button
              className="save-btn"
              onClick={handleSave}
              disabled={isSaving}
            >
              {isSaving ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    )
  );
};

export default EditCompanyModal;
