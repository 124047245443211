import React from "react";
import "./CompanyList.css";
import "bootstrap/dist/css/bootstrap.css";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

export default function CompanyList({
  companies,
  selectedCompany,
  onCompanySelect,
  onAddCompanyClick,
  onEditCompanyClick,
}) {
  return (
    <div className="company-list">
      <h5>Companies</h5>
      <ul>
        {companies.map((company) => (
          <li key={company.id}>
            <span onClick={() => onCompanySelect(company)}>
              {company.name}
            </span>
            {selectedCompany && selectedCompany.id === company.id && (
              <Button
                className="edit-company-button"
                variant="outlined"
                startIcon={<EditIcon />}
                onClick={() => onEditCompanyClick(company)}
              >
                Edit
              </Button>
            )}
          </li>
        ))}
      </ul>
      <button className="add-button" onClick={onAddCompanyClick}>
        Add Company
      </button>
    </div>
  );
}
