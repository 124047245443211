import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <div className="fllllll">
      <footer className="custom-footer">
        <div className="container justify-content-center">
          <div className="footer-content">
            <p>
              Powered by{" "}
              <strong style={{ color: "forestgreen" }}>
                Cizonet Solutions
              </strong>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
