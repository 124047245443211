import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import "./EmployeeManagement.css";
import Navbar from "../../Component/Navbar/Navbar";
import EmployeeModal from "./EmployeeModal";
import EmployeeDetails from "./EmployeeDetails";

export default function EmployeeManagement() {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false);
  const [isEmployeeDetailsOpen, setIsEmployeeDetailsOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [renderKey, setRenderKey] = useState(0);
  const location = useLocation();
  const companyId = new URLSearchParams(location.search).get("companyId");

  useEffect(() => {
    setEmployees();
  }, []);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      if (!companyId) return;
      try {
        const companyRef = doc(db, "companies", companyId);
        const companyDoc = await getDoc(companyRef);
        if (companyDoc.exists()) {
          setSelectedCompany({ id: companyDoc.id, ...companyDoc.data() });
        } else {
          console.error("No such company!");
        }
      } catch (error) {
        console.error("Error fetching company details:", error);
      }
    };

    const fetchEmployees = async () => {
      if (!companyId) return;
      try {
        const employeesCollection = collection(
          db,
          `companies/${companyId}/employees`
        );
        const employeesSnapshot = await getDocs(employeesCollection);
        const employeeList = employeesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEmployees(employeeList);
      } catch (error) {
        console.error("Error fetching employees:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyDetails();
    fetchEmployees();
  }, [companyId, renderKey]);

  const handleAddEmployeeClick = () => setIsEmployeeModalOpen(true);

  const handleCloseEmployeeModal = () => setIsEmployeeModalOpen(false);

  const handleEmployeeClick = (employee) => {
    setSelectedEmployee(employee);
    setIsEmployeeDetailsOpen(true);
  };

  const handleCloseEmployeeDetails = () => {
    setIsEmployeeDetailsOpen(false);
    setSelectedEmployee(null);
  };

  const handleEmployeeSaved = () => {
    setRenderKey((prevKey) => prevKey + 1);
  };

  return (
    <div key={renderKey}>
      <Navbar />
      <div className="employee__container">
        <div className="dashboard-header colos">
          <h5>
            Manage Employees for{" "}
            <span style={{ color: "forestgreen" }}>
              {selectedCompany?.name || "Loading..."}
            </span>
          </h5>
          <button className="add-button colos" onClick={handleAddEmployeeClick}>
            Add Employee
          </button>
        </div>

        <div className="employee__content">
          <div className="employee__grid">
            <div className="employee__card">
              {loading ? (
                <p>Loading employees...</p>
              ) : employees.length > 0 ? (
                <ul style={{ listStyleType: "decimal" }}>
                  {employees.map((employee) => (
                    <li
                      key={employee.id}
                      onClick={() => handleEmployeeClick(employee)}
                    >
                      {employee.name}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>You have no employee to manage.</p>
              )}
            </div>
          </div>
        </div>

        <EmployeeModal
          isOpen={isEmployeeModalOpen}
          onClose={handleCloseEmployeeModal}
          selectedCompany={selectedCompany}
          onEmployeeSaved={handleEmployeeSaved}
        />

        {selectedEmployee && (
          <EmployeeDetails
            isOpen={isEmployeeDetailsOpen}
            onClose={handleCloseEmployeeDetails}
            selectedCompany={selectedCompany}
            employeeDetails={selectedEmployee}
            isDisabled={true}
            onEmployeeSaved={handleEmployeeSaved}
          />
        )}
      </div>
    </div>
  );
}
