import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import "./AddEmployee.css";

export default function AddEmployee({
  company,
  onAddEmployeeClick,
  openEmployeeModal,
}) {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmployees = async () => {
      const employeesCollection = collection(
        db,
        `companies/${company.id}/employees`
      );
      const employeesSnapshot = await getDocs(employeesCollection);
      const employeeList = employeesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEmployees(employeeList);
      setLoading(false);
    };

    fetchEmployees();
  }, [company]);

  return (
    <div>
      <h6>
        Employees:{" "}
        <span
          className={
            employees.length === 0
              ? "employee-count-zero"
              : "employee-count-non-zero"
          }
        >
          {employees.length}
        </span>
      </h6>
      <button
        className="add-button mt-2"
        onClick={() =>
          navigate(`/employee-management/?companyId=${company.id}`, {
            state: { company },
          })
        }
      >
        View Employees
      </button>
    </div>
  );
}
