import React, { useState, useEffect, useRef } from "react";
import "./CompanyDetailsForm.css";

const CompanyDetailsForm = ({ registerForm, setCompanyDetails, companyDetails }) => {
  const [companyName, setCompanyName] = useState(companyDetails.name || "");
  const [address, setAddress] = useState(companyDetails.address || "");
  const [field, setField] = useState(companyDetails.field || "");
  const [size, setSize] = useState(companyDetails.size || "");
  const [yearFounded, setYearFounded] = useState(companyDetails.yearFounded || "");
  const [tags, setTags] = useState(companyDetails.departments || []);
  const [inputValue, setInputValue] = useState("");
  const formRef = useRef(null);

  useEffect(() => {
    registerForm("companyDetails", {
      form: formRef.current,
      clearForm: () => {
        setCompanyName("");
        setAddress("");
        setField("");
        setSize("");
        setYearFounded("");
        setTags([]);
      },
    });
  }, [registerForm]);

  useEffect(() => {
    setCompanyDetails({
      name: companyName,
      address,
      field,
      size,
      yearFounded,
      departments: tags,
    });
  }, [companyName, address, field, size, yearFounded, tags, setCompanyDetails]);

  function handleAddTag(event) {
    if (event.key === "Enter" && inputValue) {
      event.preventDefault();
      const tag = inputValue.trim();
      if (tag && !tags.includes(tag)) {
        setTags([...tags, tag]);
      }
      setInputValue("");
    }
  }

  function handleRemoveTag(index) {
    setTags(tags.filter((_, i) => i !== index));
  }

  return (
    <form ref={formRef} className="company__form">
      <div className="flexform">
        <div className="leftform">
          <label>
            Company Name:
            <input
              type="text"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              required
            />
          </label>
          <label>
            Company Address:
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </label>
          <label>
            Field/Industry:
            <input
              type="text"
              value={field}
              onChange={(e) => setField(e.target.value)}
              required
            />
          </label>
        </div>
        <div className="rightform">
        <label>
        Company Size:
        <div className="select">
          <select
            value={size}
            onChange={(e) => setSize(e.target.value)}
            required
            style={{
              height: "40px",
              marginTop: "10px",
            }}
          >
            {!size && <option value="">Select Size</option>}
            <option value="0-1">0-1 Employees</option>
            <option value="2-10">2-10 Employees</option>
            <option value="11-50">11-50 Employees</option>
            <option value="51-200">51-200 Employees</option>
            <option value="201-500">201-500 Employees</option>
            <option value="501-1,000">501-1,000 Employees</option>
            <option value="1,001-5,000">1,001-5,000 Employees</option>
            <option value="5,001-10,000">5,001-10,000 Employees</option>
            <option value="10,001+">10,001+ Employees</option>
          </select>
        </div>
      </label>
          <label>
            Year Founded:
            <input
              type="number"
              value={yearFounded}
              onChange={(e) => setYearFounded(e.target.value)}
              required
            />
          </label>
          <label>
            Departments:
            <div className="tag-container">
              {tags.map((tag, index) => (
                <span key={index} onClick={() => handleRemoveTag(index)} className="tag-cloud">
                  {tag} &#10005;
                </span>
              ))}
              <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleAddTag}
                placeholder="Add a department"
              />
            </div>
          </label>
        </div>
      </div>
    </form>
  );
};

export default CompanyDetailsForm;
