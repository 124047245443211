import React, { useState } from 'react';
import './PerformanceForm.css';

export default function PerformanceForm({ performancePosts, setPerformancePosts, setIsEditMode }) {
  const [text, setText] = useState('');

  const handlePost = () => {
    if (text.trim()) {
      const newPost = {
        text,
        date: new Date().toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })
      };
      setPerformancePosts([...performancePosts, newPost]);
      setText('');
      setIsEditMode(true);
    }
  };

  const handleDelete = (index) => {
    const updatedPosts = performancePosts.filter((_, i) => i !== index);
    setPerformancePosts(updatedPosts);
    setIsEditMode(true);
  };

  return (
    <div className="perf_container">
      <div className="perf_posts-container">
        {performancePosts.map((post, index) => (
          <div key={index} className="perf_post">
            <span className="perf_post-date">{post.date}</span>
            <span className="perf_post-text">{post.text}</span>
            <button className="perf_delete-button" onClick={() => handleDelete(index)}>
              Delete
            </button>
          </div>
        ))}
      </div>

      <div className="perf_input-container">
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          className="perf_text-area"
          placeholder="Write something..."
        />
        <button className="perf_post-button" onClick={handlePost}>
          Post
        </button>
      </div>
    </div>
  );
}
