import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import Navbar from "../../Component/Navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { db } from "../../firebase";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { auth } from "../../firebase";
import { useNotifications } from "../../NotificationContext";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { signOut } from "firebase/auth";
import { toast } from "react-toastify";

export default function Dashboard() {
  const [companyCount, setCompanyCount] = useState(0);
  const [employeeCount, setEmployeeCount] = useState(0);
  const { notifications, fetchNotifications } = useNotifications();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const userId = auth.currentUser?.uid;

        if (userId) {
          const companiesQuery = query(
            collection(db, "companies"),
            where("owner", "==", userId)
          );
          const companySnapshot = await getDocs(companiesQuery);
          setCompanyCount(companySnapshot.size);

          let totalEmployeeCount = 0;

          for (const companyDoc of companySnapshot.docs) {
            const companyId = companyDoc.id;
            const employeesCollection = collection(
              db,
              `companies/${companyId}/employees`
            );
            const employeesSnapshot = await getDocs(employeesCollection);
            totalEmployeeCount += employeesSnapshot.size;
          }

          setEmployeeCount(totalEmployeeCount);
        }
      } catch (error) {
        console.error("Error fetching counts:", error);
      }
    };

    fetchCounts();
    fetchNotifications();
  }, [fetchNotifications]);

  const handleLogOut = async () => {
    try {
      await signOut(auth);
      toast.success("Logged out successfully!");
    } catch (error) {
      toast.error("Failed to log out");
      console.error(error);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="home-container">
        <div className="dashboard-header">
          <h2>Welcome to HRAP Suite Dashboard</h2>
          <p>Manage your companies and employees with ease.</p>
        </div>

        <div className="dashboard-content">
          <div className="dashboard-grid">
            <div className="dashboard-card">
              <div className="company-header">
                <h5>Company Overview</h5>
                <span className="main_view-all">
                  <Link to="/company">
                    View All
                    <ArrowRightAltIcon />
                  </Link>
                </span>
              </div>
              <p>Manage your companies and employees.</p>

              <p>
                You have{" "}
                <span
                  className={
                    companyCount === 0 ? "zero-count" : "non-zero-count"
                  }
                >
                  {companyCount} {companyCount === 1 ? "Company" : "Companies"}
                </span>{" "}
                and{" "}
                <span
                  className={
                    employeeCount === 0 ? "zero-count" : "non-zero-count"
                  }
                >
                  {employeeCount}{" "}
                  {employeeCount === 1 ? "Employee" : "Employees"}
                </span>{" "}
                to manage.
              </p>
            </div>

            <div className="dashboard-card">
              <h5>Notifications</h5>

              <div className="notification-card">
                <ul>
                  {notifications.map((notification) => (
                    <li key={notification.id}>{notification.message}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="quickact">
              <h5>Quick Actions</h5>
              <div className="mt-3 quicka">
                <button
                  className="quickaction-button"
                  onClick={() => navigate("/company?modal=open")}
                >
                  Add Company
                </button>
                <button
                  className="quickupdate-button"
                  onClick={() => navigate(`/settings`)}
                >
                  Update Profile
                </button>
                <button className="quickLogout-button" onClick={handleLogOut}>
                  <PowerSettingsNewIcon /> Log Out
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
