import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./Pages/Auth/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Company from "./Pages/Company/Company";
import { auth } from "./firebase";
import ResetPassword from "./Pages/Auth/ResetPassword";
import Settings from "./Component/Settings/Settings";
import { ToastContainer } from "react-toastify";
import Footer from "./Component/Footer/Footer";
import EmployeeManagement from "./Pages/Company/EmployeeManagement";
import { NotificationProvider } from "./NotificationContext";

export default function App() {
  const [isScreenValid, setIsScreenValid] = useState(window.innerWidth >= 678);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsScreenValid(window.innerWidth >= 767);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!isScreenValid) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          textAlign: "center",
          fontSize: "18px",
          padding: "10px",
        }}
      >
        Please access this application from a desktop or tablet.
      </div>
    );
  }

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontSize: "18px",
        }}
      >
        Checking Authentication...
      </div>
    );
  }

  return (
    <NotificationProvider>
      <Router>
        <ToastContainer autoClose={2500} style={{ zIndex: 9999 }} />
        <div className="app">
          <Routes>
            <Route
              path="/"
              element={
                user ? <Navigate to="/dashboard" /> : <Navigate to="/login" />
              }
            />

            <Route
              path="/dashboard"
              element={user ? <Dashboard /> : <Navigate to="/login" />}
            />
            <Route
              path="/company"
              element={user ? <Company /> : <Navigate to="/login" />}
            />
            <Route
              path="/employee-management"
              element={user ? <EmployeeManagement /> : <Navigate to="/login" />}
            />
            <Route
              path="/settings"
              element={user ? <Settings /> : <Navigate to="/login" />}
            />

            <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </NotificationProvider>
  );
}
