import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Navbar.css";
import logo from "../Assets/images/hraplogo.png";
import { Avatar } from "@mui/material";
import { auth } from "../../firebase";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import NavDropdown from "react-bootstrap/NavDropdown";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

export default function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    setUser(auth.currentUser);
  }, []);

  const isActive = (path) => location.pathname === path;

  const isEmployeesActive = () => {
    const companyId = new URLSearchParams(location.search).get("companyId");
    return location.pathname.includes("/employee-management") && companyId;
  };

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const handleClick = () => navigate("/settings");

  useEffect(() => {
    const fetchCompanies = async () => {
      const companiesCollection = collection(db, "companies");
      const companiesSnapshot = await getDocs(companiesCollection);
      const companyList = companiesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCompanies(companyList);
    };

    fetchCompanies();
  }, []);

  const handleCompanySelect = (company) => {
    setSelectedCompany(company);
    navigate(`/employee-management/?companyId=${company.id}`);
  };

  return (
    <div className="sticky-top custom-navbar">
      <div className="container justify-content-center">
        <div className="navbar-content">
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="HRAP Suite Logo" />
          </Link>
          <div className="" id="navbarNav">
            <ul className="navbarNav">
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    isActive("/dashboard") ? "active" : ""
                  }`}
                  to="/dashboard"
                >
                  Dashboard
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${isActive("/company") ? "active" : ""}`}
                  to="/company"
                >
                  Company
                </Link>
              </li>
              <li className="nav-item">
                <NavDropdown
                  id="bg-light"
                  title="Employees"
                  menuVariant="bg-light"
                  active={isEmployeesActive()}
                >
                  {companies.length > 0 ? (
                    companies.map((company) => (
                      <NavDropdown.Item
                        key={company.id}
                        onClick={() => handleCompanySelect(company)}
                        className="dropdown-item-custom"
                      >
                        {company.name}
                      </NavDropdown.Item>
                    ))
                  ) : (
                    <NavDropdown.Item>No Companies Available</NavDropdown.Item>
                  )}
                </NavDropdown>
              </li>

              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    isActive("/settings") ? "active" : ""
                  }`}
                  to="/settings"
                >
                  Settings
                </Link>
              </li>
              <li className="nav-item">
                <div
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={handleClick}
                  style={{ cursor: "pointer" }}
                >
                  {isActive("/settings") ? (
                    <SettingsRoundedIcon
                      sx={{ width: 40, height: 40 }}
                      style={{
                        color: isActive("/settings")
                          ? "#228b22"
                          : isHovered
                          ? "#ff4500"
                          : "inherit",
                      }}
                    />
                  ) : (
                    <Avatar
                      alt="HRAP"
                      src={user?.photoURL}
                      sx={{ width: 40, height: 40 }}
                    />
                  )}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
